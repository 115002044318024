//import { useState, useEffect } from 'react';
//import axios from 'axios';
import './tutos.css';

function Tutos({ role, username }) {
    return(
        <h1>Tutoriales</h1>
    );
}

export default Tutos;