import { useState, useEffect } from 'react';
import axios from 'axios';
import './states.css';


function States({ role, username, apiRoot }) {
    const [states, setStates] = useState([]);
    const [toast, setToast] = useState({ message: '', success: false });

    // Función para mostrar un toast
    function showToast(message, success) {
        setToast({ message, success });

        // Ocultar el toast después de 2 segundos
        setTimeout(() => {
            setToast({ message: '', success: false });
        }, 2000);
    }

    useEffect(() => {
        fetchStates();
    }, [apiRoot]);

    const fetchStates = async () => {
        try {
            const response = await axios.get(apiRoot + 'go_by_logic.php?action=get_all_states');
            setStates(response.data.message); // Asigna solo el arreglo de estados al estado local
        } catch (error) {
            console.error('Error fetching states:', error);
            showToast('Error fetching states', false); // Muestra un mensaje de error
        }
    };
 
    // Función para manejar el cambio de selección del estado
    const handleStateSelectChange = async (stateId) => {
        const formData = new FormData();
        formData.append('action', 'handle_state');
        formData.append('id', stateId);
        formData.append('username', username);
        formData.append('role', role);

  
        try {
            const response = await axios.post(apiRoot + 'go_by_logic.php', formData);

            if (response.data.success) {
              showToast(response.data.message ,  true );

            } else {
              showToast('Error al actualizar el orden: '+ response.data.message, false );
            }

        } catch (error) {
            showToast('Error de conexión.',false );
        }


        const updatedStates = states.map(state =>
            state.id === stateId ? { ...state, selected: !state.selected } : state
        );
        setStates(updatedStates);
    };

    return (
        <div>
            {/* Renderiza el estado del toast si hay un mensaje */}
            {toast.message && (
                <div className={`toast ${toast.success ? 'success' : 'error'}`}>
                    {toast.message}
                </div>
            )}
            <h2>Seleccione los estados que desea activar</h2>
            {/* Renderiza la lista de estados con checkboxes */}
            <ul className='states_list'>
                {states.map(state => (
                    <li className='states_list_li' key={state.id}>
                        <input
                            className='checkbox'
                            type="checkbox"
                            checked={state.selected}
                            onChange={() => handleStateSelectChange(state.id)}
                        />
                         <b>{state.state_code}</b> - {state.state_name}
                    </li>
                ))}
            </ul>
        </div>
    );
}


export default States;