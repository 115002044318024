import { useState, useEffect } from 'react';
import axios from 'axios';
import './stateInfo.css';

function StateInfo({ role, username, apiRoot, showToast }) {
    const [states, setStates] = useState([]);
    const [selectedContent, setSelectedContent] = useState('');
    const [selectedState, setSelectedState] = useState(null);
    const [toast, setToast] = useState({ message: '', success: false });

    // Función para mostrar un toast
    function showToast(message, success) {
        setToast({ message, success });

        // Ocultar el toast después de 2 segundos
        setTimeout(() => {
            setToast({ message: '', success: false });
        }, 2000); // Mostrar durante 2 segundos
    }

    useEffect(() => {
        // Función para obtener la lista de estados desde el API
        const fetchStates = async () => {
            try {
                const response = await axios.get(`${apiRoot}go_by_logic.php?action=get_all_states`);
                // Ordenar los estados para que los seleccionados aparezcan primero
                const sortedStates = response.data.message.sort((a, b) => b.selected - a.selected);
                setStates(sortedStates);

            } catch (error) {
                console.error('Error fetching states:', error);
                showToast('Error fetching states'); // Muestra un mensaje de error
            }
        };

        fetchStates();
    }, [apiRoot]);

    // Copiar al clipboard
    async function copyToClipboard(text) {
        try {
            await navigator.clipboard.writeText(text); // Usar la API moderna
            //console.log('Copiado: ' + text);
        } catch (err) {
            console.error('Error al copiar: ', err);
        }
    }

    //  clic en un estado
    const handleStateClick = async (stateId) => {
        setSelectedState(stateId);
        setSelectedContent('');
        document.getElementById('insurerInfoContent').value = '';


        try {
            const response = await axios.get(`${apiRoot}info?action=get_state_info&stateId=${stateId}`);
            setSelectedContent(response.data.message);
            document.getElementById('insurerInfoContent').value = response.data.message;
            
        } catch (error) {
            console.error('Error fetching state content:', error);
            showToast('Error fetching state content', false);
        }
    };

    // Guardar Info en un estado
    const handleUpdateState = async (stateId) => {
        
        const content = document.getElementById('insurerInfoContent').value;
        const state_name = states.find(state => state.id === stateId)['state_name'];

        const save_state_info = {
            action: 'save_state_info',
            stateId: stateId,
            content: content,
            state_name: state_name,
            role: role, 
            username: username,
          };
        //console.log(state_name);

        try {
            const response = await axios.post(`${apiRoot}info`,save_state_info);             
            showToast(response.data.message ,  true );

        } catch (error) {
            console.error('Error fetching state content:', error);
            showToast('Error fetching state content', false); 
        }
    };

    // Guardar Info en un estado
    const handleCleareState = async (stateId) => {

        setSelectedContent(' ');

        const state_name = states.find(state => state.id === stateId)['state_name'];

        const save_state_info = {
            action: 'save_state_info',
            stateId: stateId,
            content: ' ',
            state_name: state_name,
            role: role, 
            username: username,
            };
        //console.log(state_name);

        try {
            const response = await axios.post(`${apiRoot}info`,save_state_info);             
            showToast(response.data.message ,  true );

        } catch (error) {
            console.error('Error fetching state content:', error);
            showToast('Error fetching state content', false); 
        }
    };

    return (
        <div className="insurers-container">
            <div className="states-list">
                <ul>
                    {states.map(state => (
                        <li
                            key={state.id}
                            onClick={() => handleStateClick(state.id)}
                            className={`state-buttons-mod ${state.id === selectedState ? 'active' : ''} ${state.selected === 1 ? 'selected' : 'not-selected'}`}
                        >    
                            {state.state_name}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="content-box">
                <textarea  
                    id="insurerInfoContent"
                    value={selectedContent}
                    onChange={(e) => setSelectedContent(e.target.value)}
                />
                <div
                    className="content-display"
                    id="content-display"
                    dangerouslySetInnerHTML={{ __html: selectedContent }}
                />
                <div className="content-buttons">
                    <button 
                    id='eliminar'
                    onClick={() => handleCleareState(selectedState)}>
                        Eliminar
                    </button>
                    <button 
                    id='Guardar'
                    onClick={() => handleUpdateState(selectedState)}>
                        Guardar
                    </button>
                </div>
            </div> 
            <div className="styles-list">
            <ul>
                <li onClick={() => copyToClipboard('<h1></h1>')}>&lt;h1&gt;&lt;/h1&gt;</li>
                <li onClick={() => copyToClipboard('<h2></h2>')}>&lt;h2&gt;&lt;/h2&gt;</li>
                <li onClick={() => copyToClipboard('<h3></h3>')}>&lt;h3&gt;&lt;/h3&gt;</li>
                <li onClick={() => copyToClipboard('<h4></h4>')}>&lt;h4&gt;&lt;/h4&gt;</li>
                <li onClick={() => copyToClipboard('<h5></h5>')}>&lt;h5&gt;&lt;/h5&gt;</li>
                <li onClick={() => copyToClipboard('<h6></h6>')}>&lt;h6&gt;&lt;/h6&gt;</li>
                <li onClick={() => copyToClipboard('<p></p>')}>&lt;p&gt;&lt;/p&gt;</li>
                <li onClick={() => copyToClipboard('<strong></strong>')}>&lt;strong&gt;&lt;/strong&gt; (Negrita)</li>
                <li onClick={() => copyToClipboard('<em></em>')}>&lt;em&gt;&lt;/em&gt; (Cursiva)</li>
                <li onClick={() => copyToClipboard('<u></u>')}>&lt;u&gt;&lt;/u&gt; (Subrayado)</li>
                <li onClick={() => copyToClipboard('<del></del>')}>&lt;del&gt;&lt;/del&gt; (Tachado)</li>
                <li onClick={() => copyToClipboard('<mark></mark>')}>&lt;mark&gt;&lt;/mark&gt; (Resaltado)</li>
                <li onClick={() => copyToClipboard('<small></small>')}>&lt;small&gt;&lt;/small&gt; (Texto pequeño)</li>
                <li onClick={() => copyToClipboard('<big></big>')}>&lt;big&gt;&lt;/big&gt; (Texto grande)</li>
                <li onClick={() => copyToClipboard('<blockquote></blockquote>')}>&lt;blockquote&gt;&lt;/blockquote&gt; (Cita)</li>
                <li onClick={() => copyToClipboard('<code></code>')}>&lt;code&gt;&lt;/code&gt; (Código)</li>
                <li onClick={() => copyToClipboard('<pre></pre>')}>&lt;pre&gt;&lt;/pre&gt; (Texto preformateado)</li>
                <li onClick={() => copyToClipboard('<ul><li></li></ul>')}>&lt;ul&gt;&lt;li&gt;&lt;/li&gt;&lt;/ul&gt;</li>
                <li onClick={() => copyToClipboard('<ol><li></li></ol>')}>&lt;ol&gt;&lt;li&gt;&lt;/li&gt;&lt;/ol&gt;</li>
                <li onClick={() => copyToClipboard('<li></li>')}>&lt;li&gt;&lt;/li&gt;</li>
                <li onClick={() => copyToClipboard('<br/>')}>&lt;br/&gt;</li>
                <li onClick={() => copyToClipboard('""')}>" "</li>
                <li onClick={() => copyToClipboard('<input type="checkbox" />')}>&#9744;</li>
                <li onClick={() => copyToClipboard('<input type="radio" name="example" />')}>&#9898;</li>
                <li onClick={() => copyToClipboard('&#8728;')}>&#8728;</li>
                <li onClick={() => copyToClipboard('&bull;')}>&bull;</li>
                <li onClick={() => copyToClipboard("<a href='' target='_blank'></a>")}>&lt;a href=""&gt;&lt;/a&gt;</li>
                <li onClick={() => copyToClipboard("<img src='' alt='' />")}>&lt;img src="" alt=""/&gt;</li>
                <li onClick={() => copyToClipboard('<table><tr><td></td></tr></table>')}>&lt;table&gt;&lt;tr&gt;&lt;td&gt;&lt;/td&gt;&lt;/tr&gt;&lt;/table&gt;</li>
                <li onClick={() => copyToClipboard('<div></div>')}>&lt;div&gt;&lt;/div&gt;</li>
                <li onClick={() => copyToClipboard('<hr/>')}>&lt;hr/&gt;</li>
                <li onClick={() => copyToClipboard('<sub></sub>')}>&lt;sub&gt;&lt;/sub&gt; (Subíndice)</li>
                <li onClick={() => copyToClipboard('<sup></sup>')}>&lt;sup&gt;&lt;/sup&gt; (Superíndice)</li>
                <li onClick={() => copyToClipboard('<address></address>')}>&lt;address&gt;&lt;/address&gt; (Dirección)</li>
                <li onClick={() => copyToClipboard('<cite></cite>')}>&lt;cite&gt;&lt;/cite&gt; (Cita de obra)</li>
                <li onClick={() => copyToClipboard('&#10004;')}>✔️ Checkmark</li>
                <li onClick={() => copyToClipboard('&#10006;')}>❌ Cross</li>
                <li onClick={() => copyToClipboard('&#9733;')}>⭐ Star</li>
                <li onClick={() => copyToClipboard('&#9829;')}>❤️ Heart</li>
                <li onClick={() => copyToClipboard('&#9888;')}>⚠️ Exclamation</li>
                <li onClick={() => copyToClipboard('&#8505;')}>ℹ️ Information</li>
                <li onClick={() => copyToClipboard('&#9745;')}>☑️ Check Box</li>
                <li onClick={() => copyToClipboard('&#8594;')}>➡️ Arrow Right</li>
                <li onClick={() => copyToClipboard('&#128276;')}>🔔 Bell</li>
            </ul>

            </div>
            {toast.message && (
                <div className={`toast ${toast.success ? 'success' : 'error'}`}>
                    {toast.message}
                </div>
            )}
        </div>
    );
}

export default StateInfo;
