import './procedures.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Procedure({ role, username, apiRoot }) {
  const [newProcedureName, setNewProcedureName] = useState('');
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [procedures, setProcedures] = useState([]);
  const [toast, setToast] = useState({ message: '', success: false });
  const [isLoading, setIsLoading] = useState(false);

  function showToast(message, success) {
    setToast({ message, success });

    // Hide the toast after 2 seconds
    setTimeout(() => {
        setToast({ message: '', success: false });
    }, 2000); // Display for 2 seconds
  }

  // Cargar procedimientos al iniciar el componente
  useEffect(() => {
    fetchProcedures();
  }, []);

  const fetchProcedures = async () => {
    try {
      const response = await axios.get(apiRoot + 'go_by_logic.php?action=get_all_procedures');
      setProcedures(response.data.message); // Asigna solo el arreglo de procedimientos al estado local
    } catch (error) {
      console.error('Error fetching procedures:', error);
      showToast('Error fetching procedures', false); // Muestra un mensaje de error
    }
  };

  const handleAddProcedure = async () => {
    if (newProcedureName.trim() !== '') {
      const formData = new FormData();
      formData.append('action', 'add_procedure');
      formData.append('name', newProcedureName);
      formData.append('username', username);
      formData.append('role', role);

      try {
        const response = await axios.post(apiRoot + 'go_by_logic.php', formData);
        if (response.data.success) {
          showToast(response.data.message, true);
          setNewProcedureName('');
          fetchProcedures(); // Recargar procedimientos después de añadir uno nuevo
        } else {
          showToast('Error al añadir el procedimiento: ' + response.data.message, false);
        }
      } catch (error) {
        console.error('Error adding procedure:', error);
        showToast('Error de conexión al añadir el procedimiento.', false);
      }
    }
  };

  const handleDeleteProcedure = async (procedureId) => {
    const formData = new FormData();
    formData.append('action', 'delete_procedure');
    formData.append('id', procedureId);
    formData.append('username', username);
    formData.append('role', role);

    try {
      const response = await axios.post(apiRoot + 'go_by_logic.php', formData);
      if (response.data.success) {
        showToast(response.data.message, true);
        fetchProcedures(); // Recargar procedimientos después de eliminar uno
        if (selectedProcedure === procedureId) {
          setSelectedProcedure(null);
        }
      } else {
        showToast('Error al eliminar el procedimiento: ' + response.data.message, false);
      }
    } catch (error) {
      console.error('Error deleting procedure:', error);
      showToast('Error de conexión al eliminar el procedimiento.', false);
    }
  };

  const handleAddPDF = async (procedureId, pdfFile) => {
    if (!pdfFile) {
      console.error('No se ha seleccionado ningún archivo PDF');
      return;
    }

    const formData = new FormData();
    formData.append('procedureId', procedureId);
    formData.append('pdf', pdfFile);

    try {
      const response = await axios.post(apiRoot + 'upload_pdf', formData);
      if (response.data.success) {
        const updatedProcedures = procedures.map((p) =>
          p.id === procedureId ? { ...p, pdf: response.data.pdfUrl } : p
        );
        setProcedures(updatedProcedures);
        console.log('PDF subido con éxito');
      } else {
        console.error('Error al subir el PDF:', response.data.message);
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
    }
  };

  const onDeletePDF = async (procedureId) => {
    const formData = new FormData();
    formData.append('action', 'delete_pdf');
    formData.append('id', procedureId);
    formData.append('username', username);
    formData.append('role', role);

    try {
      const response = await axios.post(apiRoot + 'go_by_logic.php', formData);
      if (response.data.success) {
        const updatedProcedures = procedures.map((p) =>
          p.id === procedureId ? { ...p, pdf: null } : p
        );
        setProcedures(updatedProcedures);
        console.log('PDF eliminado con éxito');
      } else {
        console.error('Error al eliminar el PDF:', response.data.message);
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
    }
  };

  const handleProcedureClick = (procedureId) => {
    setSelectedProcedure(procedureId);
  };

  return (
    <div className="procedure-container">
      <aside className="admin_left_aside">
        <h2>Procedimientos</h2>
        <div className="agg_procedure_div">
          <input
            name="new_procedure"
            type="text"
            id="newProcedureInput"
            placeholder="Nuevo procedimiento"
            value={newProcedureName}
            onChange={(e) => setNewProcedureName(e.target.value)}
          />
          <button className="agg_procedure_button" onClick={handleAddProcedure}>
            Añadir
          </button>
        </div>
        <ul>
          {procedures.map((procedure) => (
            <li
              key={procedure.id}
              onClick={() => handleProcedureClick(procedure.id)}
              className={`procedure-item ${procedure.id === selectedProcedure ? 'active' : ''}`}
            >
              <button
                className="delete_procedure_button"
                onClick={(e) => {
                  e.stopPropagation(); // Evitar que el click del botón active el procedimiento
                  handleDeleteProcedure(procedure.id);
                }}
              >
                X
              </button>
              {procedure.name}
            </li>
          ))}
        </ul>
      </aside>

      {selectedProcedure && (
        <section className="procedure-content">
          <h2>Contenido del Procedimiento</h2>
          {procedures.find((p) => p.id === selectedProcedure)?.pdf ? (
            <>
              <iframe
                src={procedures.find((p) => p.id === selectedProcedure)?.pdf}
                width="600"
                height="400"
                title="PDF"
              ></iframe>
              <button onClick={() => onDeletePDF(selectedProcedure)}>
                Eliminar PDF
              </button>
            </>
          ) : (
            <div>
              <p>No hay PDF asociado a este procedimiento.</p>
              <input type="file" id="pdfUpload" />
              <button onClick={() => handleAddPDF(selectedProcedure, document.getElementById('pdfUpload').files[0])}>
                Subir PDF
              </button>
            </div>
          )}
        </section>
      )}
    </div>
  );
}

export default Procedure;
