import { useState, useEffect } from 'react';
import axios from 'axios';
import './guidelines.css';

function Guidelines({ role, username, apiRoot, }) {
    const [guidelines, setGuidelines] = useState([]);
    const [selectedContentGuidelines, setSelectedContentGuidelines] = useState('');
    const [selectedGuidelines, setSelectedGuidelines] = useState(null);


    useEffect(() => {
        const fetchGuidelines = async () => {
            try {
                const response = await axios.get(`${apiRoot}go_by_logic.php?action=get_all_companies`);
                setGuidelines(response.data.message);
            } catch (error) {
                console.error('Error fetching guidelines:', error);
            }
        };

        fetchGuidelines();
    }, [apiRoot]);


    // Función para manejar el clic en una guía
    const handleGuidelineClick = async (guidelineId) => {
        setSelectedGuidelines(guidelineId);
        setSelectedContentGuidelines(' ');

        try {
            const response = await axios.get(`${apiRoot}info?action=get_guideline_info&guidelineId=${guidelineId}`);
            if(response){
                setSelectedContentGuidelines(response.data.message);
            } else {
                setSelectedContentGuidelines(' ');
            }
        } catch (error) {
            console.error('Error fetching guideline content:', error);
        }
    };


    return (
        <div className="guidelines-container">
            <div className="guidelines-list">
                <ul>
                    {guidelines.map(guideline => (
                        <li
                            key={guideline.id}
                            onClick={() => handleGuidelineClick(guideline.id)}
                            className={`guideline-buttons-mod ${guideline.id === selectedGuidelines ? 'active' : ''}`}
                        >
                            {guideline.name}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="guidelines-content-box">
                <div
                    className="guidelines-content-display"
                    id="content-display"
                    dangerouslySetInnerHTML={{ __html: selectedContentGuidelines }}
                />
            </div>
        </div>
    );
}

export default Guidelines;
